import { render, staticRenderFns } from "./blogs-with-pagination.vue?vue&type=template&id=06892804&scoped=true&"
import script from "./blogs-with-pagination.vue?vue&type=script&lang=js&"
export * from "./blogs-with-pagination.vue?vue&type=script&lang=js&"
import style0 from "../../assets/css/fontawesome-all.css?vue&type=style&index=0&id=06892804&scoped=true&lang=css&"
import style1 from "../../assets/css/font-awesome.min.css?vue&type=style&index=1&id=06892804&scoped=true&lang=css&"
import style2 from "../../assets/css/styles.css?vue&type=style&index=2&id=06892804&scoped=true&lang=css&"
import style3 from "./blogs-with-pagination.vue?vue&type=style&index=3&id=06892804&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06892804",
  null
  
)

export default component.exports