<template>
  <!-- <div> -->
  <div class="inner-pages hd-white">
    <section style="background: white;" class="blog blog-section">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-xs-12">
            <div v-if="blogs" class="row d-flex justify-content-center">
              <b-table
                class="mmsett"
                ref="table"
                :current-page="currentPage"
                :per-page="perPage"
                :tbody-tr-class="'transparentTr'"
                :fields="fields"
                show-empty
                :items="itemsProvider"
              >
                <template #empty>
                  <div class="d-flex justify-content-center">
                    <div
                      v-if="processing"
                      style="color: #55904e"
                      class="text-center my-2"
                    >
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>Loading...</strong>
                    </div>
                    <div v-else>No Blogs Found</div>
                  </div>
                </template>
                <template #table-busy>
                  <div style="color: #55904e" class="text-center my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
                <template #cell(custom)="row">
                  <single-blog
                    :blog="row.item"
                    :key="row.item.id"
                    :class="[
                      'col-md-12',
                      'col-xs-12',
                      row.index != 0 ? 'space' : '',
                      'hover',
                    ]"
                  ></single-blog>
                </template>
              </b-table>
              <div>
                <b-pagination
                  ref="pagination"
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="my-table"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
const singleBlog = () =>
  import(/* webpackChunkName: "single-blog" */ "../../components/single-blog");
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      processing: false,
      currentPage: 1,
      perPage: 10,
      fields: [
        {
          key: "custom",
          thClass: ["d-none"],
          tdClass: "remove-border",
        },
      ],
    };
  },
  watch: {
    currentPage(val) {
      this.refresh();
    },
  },
  computed: {
    ...mapGetters({
      blogs: "getBlogs",
      rows: "getTotal"
    }),
  },
  created() {},
  mounted() {
    this.refresh();
  },
  methods: {
    async refresh() {
      this.processing = true;
      try {
        const response = await this.$store.dispatch("blogs", {
          page: this.currentPage,
          perPage: this.perPage,
        });
        this.processing = false;
        if (response === true) {
          this.$refs.table.refresh();
        }
      } catch (err) {
        this.processing = false;
      }
    },
    itemsProvider(ctx, callback) {
      return this.blogs || [];
    },
  },
  components: {
    singleBlog,
  },
};
</script>

<style scoped src="../../assets/css/fontawesome-all.css"></style>
<style scoped src="../../assets/css/font-awesome.min.css"></style>
<style scoped src="../../assets/css/styles.css"></style>

<style scoped>
@media (min-width: 400px) and (max-width: 700px) {
  .mmsett {
    margin-bottom: 0px !important;
  }
}

div >>> .page-item.active .page-link {
  background-color: #55904e;
  border-color: #55904e;
}
div >>> .page-link {
  color: #55904e;
}
div >>> .remove-border {
  border-top: none;
  padding: 0px;
}
.transparentTr {
  background-color: transparent !important;
}
</style>
